/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/tiny-slider.css';
import '../styles/glightbox.min.css';
import '../styles/lineicons.css';
import '../styles/app.css';
import '../images/favicon.svg';
import '../images/logo_y_30h.png';
import '../images/waze.png';
import '../images/berlat.png';
import '../images/berlat_bg.png';
import '../images/1.webp';
import '../images/2.webp';
import '../images/berlat_bg.png';
import '../images/logo_corona_white.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import $ from "jquery";
global.$ = global.jQuery = $;
import './ageModal';
import './main.js';