import $ from "jquery";
$(document).ready(function () {
    if (!localStorage.getItem("ageVerified")) {
        $("#ageModal").fadeIn();
    }

    $("#yesButton").click(function () {
        localStorage.setItem("ageVerified", "true");
        $("#ageModal").fadeOut();
    });

    $("#noButton").click(function () {
        window.location.href = "https://nra.lv/veseliba/438052-veselibas-ministrija-alkohola-lietosana-ir-kaitiga-veselibai-jebkuros-daudzumos.htm";
    });
});